import { UPDATE, GET_LIST, GET_ONE } from "react-admin";

import { API_URL } from "../../../../Config/env";

const requestHandler = (type, params) => {
  switch (type) {
    case GET_LIST: {
      return {
        url: `${API_URL}/admin/crypto_payout_providers`,
        method: "get"
      };
    }
    case GET_ONE: {
      return {
        url: `${API_URL}/admin/crypto_payout_providers/${params.id}`,
        method: "get",
      }
    }
    case UPDATE: {
      const {
        attributes: {
         is_active,
         autopay_balance_validation
        }
      } = params.data;

      return {
        url: `${API_URL}/admin/crypto_payout_providers/${params.id}`,
        method: "put",
        data: { is_active, autopay_balance_validation }
      };
    }
    default:
      return null;
  }
};

export default requestHandler;
