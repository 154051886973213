import { GET_LIST } from 'react-admin';

import { API_URL } from '../../../../Config/env';
import { RESOURCES } from '../../../../Helpers/ResourceHelper';

const requestHandler = (type, params, resource) => {

  const walletType = resource === RESOURCES.custody_wallets_origin ? "0" : "1";

  let { pagination: { page, perPage } } = params;   
  
    switch (type) {
        case GET_LIST: {
            return {
              url: `${API_URL}/admin/custody_wallets?q[wallet_type_eq]=${walletType}&page=${page}&count=${perPage}`,
                method: 'get',
            };
        }
        default: {
            return {

            }
        }
    }
}

export default requestHandler;
