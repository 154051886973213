export const WALLET_TRANSACTIONS = "custody_wallets/transactions";
export const WALLET_DESTINATION = "custody_wallets_destination";
export const WALLET_ORIGIN = "custody_wallets_origin";

export const DEFAULT_FORMDATA = {
  currency: "",
  amount: "",
  walletOriginId: "",
  walletDestinationId: "",
  walletName: "",
  network: "",
  walletDirection: "",
  externalId: "",
  contract: "",
  description: "",
}
