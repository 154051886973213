import React from 'react'
import RichTextInput from 'ra-input-rich-text';
import { Col, Container, Row } from 'react-grid-system';
import { Card, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import {
  BooleanInput,
  TextInput,
  NumberInput,
  SelectArrayInput,
  SelectInput,
  FormDataConsumer,
  required,
  maxLength,
} from 'react-admin';

import { DOCUMENT_TYPE, REFERRER_PRIZE_TYPE, BANK_ACCOUNT_PROVIDERS_TYPE } from '../../../../../Utils';

const GeneralSettingsTab = (propsPrices) => {
  const { record, setIsRechargeStatus, validateModalRoute } = propsPrices;

  if (record.attributes.is_recharge) {
    setIsRechargeStatus(record.attributes.is_recharge);
  };

  return (
    <Card style={{ width: '100%' }}>
      <Container style={{ width: '100%', maxWidth: '100%' }}>
        <Row>
          <Col xs={3}>
            <TextInput
              source={'attributes.currency'}
              label={'Moneda'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <TextInput
              source={'attributes.currency_name'}
              label={'Moneda local'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <TextInput
              source={'attributes.currency_plural_name'}
              label={'Moneda local en plural'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.size_of_digits_for_sms'}
              label={'Cantidad de digitos SMS'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.currency_decimals'}
              label={'Cantidad decimales'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <TextInput
              source={'attributes.currency_iso_code'}
              label={'ISO de Moneda local'}
              format={iso => iso.toUpperCase()}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <TextInput
              source={'attributes.flag_url'}
              label={'URL de la bandera'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <TextInput
              source={'attributes.society'}
              label={'Texto de la sociedad'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_referrals_system'}
              label={'Sistema de referidos'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 20 }}>
              <SelectInput
                native
                label="¿Quién recibe dinero en referidos?"
                source="attributes.send_prize_to"
                choices={REFERRER_PRIZE_TYPE}
                style={{ width: 350 }}
              />
            </div>
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.min_deposit_amount_referrer'}
              label={'Monto mínimo para recibir recarga por referido'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.referrer_prize'}
              label={'Monto a recibir por referido'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.min_amount_exchange'}
              label={'Monto mínimo para intercambiar'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.min_admin_balance_exchange'}
              label={'Monto mínimo de balance del admin'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.transfer_min_amount_usd'}
              label={'Monto mínimo a transferir en USD'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.min_amount_payment'}
              label={'Monto mínimo para recibir pagos'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.min_amount_sents'}
              label={'Monto mínimo para envíos internos en USD'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.min_amount_recharge'}
              label={'Monto mínimo para recargas'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.withdrawal_fee'}
              label={'Comisión de retiro %'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.deposit_fee'}
              label={'Comisión de recarga %'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.min_amount_withdrawal'}
              label={'Monto mínimo retiros cuenta propia'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <TextInput
              source={'attributes.message_time_withdrawal'}
              label={'Tiempo estimado'}
              style={{ width: '100%' }}
              validate={[required(), maxLength(50)]}
              multiline
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.card_cost_individual'}
              label={'Costo de tarjeta (natural) en USD'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.card_cost_business'}
              label={'Costo de tarjeta (business) en USD'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.usd_account_cost_individual'}
              label={'Costo de cuenta (individual) en EE.UU.'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.usd_account_cost_business'}
              label={'Costo de cuenta (business) en EE.UU.'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.card_reapplication_cost_individual'}
              label={'Costo de resolicitud de tarjeta (natural) en USD'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              source={'attributes.card_reapplication_cost_business'}
              label={'Costo de resolicitud de tarjeta (business) en USD'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <SelectArrayInput
              label="Tipos de documento para recargar"
              source="attributes.recharge_documents_types"
              choices={DOCUMENT_TYPE}
              style={{ width: 300 }}
            />
          </Col>
          <Col xs={3}>
            <SelectInput
              native
              label="Proveedor para el autollenado"
              source="attributes.beneficiary_search_provider"
              choices={BANK_ACCOUNT_PROVIDERS_TYPE}
              style={{ width: 300 }}
            />
          </Col>
          <Col xs={3}>
            <TextInput
              source={'attributes.benericiary_search_label'}
              label={'Texto para mostrar del autollenado'}
              style={{ width: '100%' }}
              multiline
            />
          </Col>
          <Col xs={3}>
            <TextInput
              source={'attributes.account_code_regex'}
              label={'Expresión regular para el autollenado'}
              style={{ width: '100%' }}
              multiline
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_beneficiary_search'}
              label={'Permite autollenar información en transferencias'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_register'}
              label={'País de residencia'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_birth'}
              label={'País de nacimiento'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_btc'}
              label={'Bitcoin'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_recharge'}
              label={'Recargas'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_payments'}
              label={'Pagos'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_business_payments'}
              label={'Pagos business'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_sent_vita_card'}
              label={'Enviar a Vita Cards'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_vita_card'}
              label={'Recibir Vita Cards'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_service_payment'}
              label={'Servicios'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_usdt'}
              label={'USDT'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_usdc'}
              label={'USDC'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_business_api'}
              label={'Api Business'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_multi_currency'}
              label={'Multi Moneda'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_usd_account'}
              label={'Cuenta USD'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.allow_automatic_payments'}
              label={'Pagos automáticos fiat'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.allow_automatic_payments_crypto'}
              label={'Pagos automáticos crypto'}
              style={{ width: '100%' }}
            />
          </Col>
          {record.attributes.is_prices_config && record.attributes.is_rules ? (
            <Row style={{ color: '#FFFFFF', width: '100%', paddingTop: 40, paddingBottom: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Col xs={3}>
                <BooleanInput
                  source={'attributes.is_cashout'}
                  label={'Retiros'}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={3}>
                <BooleanInput
                  source={'attributes.is_sent_receive'}
                  label={'Recibir envíos'}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={3}>
                <BooleanInput
                  source={'attributes.is_sent_available'}
                  label={'Enviar'}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={3}>
                <BooleanInput
                  source={'attributes.is_transfer'}
                  label={'Remesas'}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={3}>
                <BooleanInput
                  source={'attributes.is_add_international_favorites'}
                  label={'Enviar a terceros en país de residencia'}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={12} style={{ marginLeft: '2%' }}>
                <BooleanInput
                  source={'attributes.is_modal_route'}
                  label={'Más información'}
                  style={{ width: '100%' }}
                />
              </Col>
              <FormDataConsumer>
                {({ formData, ...rest }) => formData.attributes.is_modal_route &&
                  <Col xs={12} style={{ marginLeft: '2%' }} className={'rich-text-country'}>
                    <RichTextInput
                      source="attributes.modal_route"
                      label={'Más información'}
                      validate={validateModalRoute}
                      theme='snow'
                      style={{ minHeight: '300px' }}
                      toolbar={[
                        ['bold', 'italic', 'underline', 'strike', 'link'],        // toggled buttons
                        // ['blockquote', 'code-block'],
                        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                        // [{ 'direction': 'rtl' }],                         // text direction
                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{
                          'color': [
                            "#333333",
                            "#4F4F4F",
                            "#777777",
                            "#BDBDBD",
                            "#F2F2F2",
                            "#FFFFFF",
                            "#00BB8D",
                            "#005FEE",
                            "#DD5335",
                            "#F2994A",
                          ]
                        }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'font': [] }],
                        [{ 'align': [] }],
                        ['clean']                                         // remove formatting button
                      ]}
                    />
                  </Col>
                }
              </FormDataConsumer>
            </Row>
          ) : (
            <Col xs={12} style={{ color: '#FFFFFF', paddingTop: 40, paddingBottom: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Warning style={{ color: '#ffd500', fontSize: '3rem', marginRight: 10 }} />
              <div style={{ textAlign: 'center', maxWidth: 600 }}>
                Si deseas habilitar este país para envíos internos e internacionales, debes crear la configuración de precios para las respectivas monedas soportadas por Vita y reglas de transferencias
              </div>
            </Col>
          )}
        </Row>
      </Container>
      <Container style={{ width: '100%', maxWidth: '100%' }}>
        <Typography variant="h4" style={{ color: 'white' }} >
          Límites para beneficiarios
        </Typography>
        <Row style={{ color: '#FFFFFF', width: '100%', paddingTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_daily_recipient_limit'}
              label={'Límite diario'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={5}>
            <TextInput
              source={'attributes.daily_recipient_limit'}
              label={'Indique el límite diario'}
              style={{ width: '100%' }}
            />
          </Col>
        </Row>
        <Row style={{ color: '#FFFFFF', width: '100%', paddingBottom: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Col xs={3}>
            <BooleanInput
              source={'attributes.is_monthly_recipient_limit'}
              label={'Límite mensual'}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={5}>
            <TextInput
              source={'attributes.monthly_recipient_limit'}
              label={'Indique el límite mensual'}
              style={{ width: '100%' }}
            />
          </Col>
        </Row>
      </Container>
    </Card>
  )
};

export default GeneralSettingsTab;