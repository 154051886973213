import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const fetchCountries = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRIES.LIST,
    });
};

const fetchRegisterCountries = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRIES.RESIDENCE_LIST,
    });
};

const fetchCountriesAdmin = async (isAll = false, queryParams = '') => {
    let {url} = ENDPOINTS.ADMIN.COUNTRIES.LIST_ADMIN;

    if (isAll) {
        url = `${url}&page=1&count=9000`;
    }

    url = `${url}${queryParams}`

    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRIES.LIST_ADMIN,
        url,
    });
};

const fetchFilterCountriesAdmin = async (filter) => {
    let {url} = ENDPOINTS.ADMIN.COUNTRIES.LIST_ADMIN;

    if (filter) {
        url = `${url}&${filter}`;
    }

    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRIES.LIST_ADMIN,
        url,
    });
};

const fetchProvinces = async (id) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRIES.STATES(id),
    });
};

const fetchCities = async (id) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRIES.CITIES(id),
    });
};

const activateCashout = async (id) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRIES.ACTIVATE_CASHOUT(id),
    });
};

const deactivateCashout = async (id) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRIES.DEACTIVATE_CASHOUT(id),
    });
};

const getLimits = async (id) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRIES.GET_LIMITS(id)
    });
};

const setLimits = async (id, payload) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRIES.SET_LIMITS(id),
        data: {data: payload},
    });
};

const getActiveCountriesCurrencies = async (id) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRY_CURRENCY.GET(id)
    });
}

const disabledCountriesCurrencies = async (id) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRY_CURRENCY.DISABLED_COUNTRIES_CURRENCIES(id)
    });
}

const enableCurrency = async (id, currency) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRY_CURRENCY.ENABLE_CURRENCY(id),
        data: {currency},
    });
}

const manageDefaultCurrency = async (country_id, currency, value) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRY_CURRENCY.MANAGE_DEFAULT_CURRENCY(country_id),
        data: {currency, value},
    });
}

const fetchCountryPayoutProviders = async(countryId) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRIES.GET_PAYOUT_PROVIDERS(countryId)
    })
}

const updateCountryPayoutProvider = async(countryId, itemId, data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRIES.UPDATE_PAYOUT_PROVIDER(countryId, itemId),
        data
    })
}

// Automatic payments fiat

const retrieveAutomaticPaymentRoutes = async (countryId) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.AUTOMATIC_PAYMENT_ROUTES.LIST(countryId)
    });
}

const updateAutomaticPaymentRoute = async (countryId, automaticPaymentRouteId, payload) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.AUTOMATIC_PAYMENT_ROUTES.UPDATE(countryId, automaticPaymentRouteId),
        data: payload
    });
}

const deleteAutomaticPaymentRoute = async (countryId, automaticPaymentRouteId) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.AUTOMATIC_PAYMENT_ROUTES.DELETE(countryId, automaticPaymentRouteId)
    });
}

const createAutomaticPaymentRoute = async (countryId, payload) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.AUTOMATIC_PAYMENT_ROUTES.CREATE(countryId),
        data: payload
    });
}

// Automatic payments crypto

const retrieveAutomaticPaymentCryptoRoutes = async (countryId) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.AUTOMATIC_PAYMENT_CRYPTO_ROUTES.LIST(countryId)
    });
}

const updateAutomaticPaymentCryptoRoute = async (countryId, automaticPaymentRouteId, payload) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.AUTOMATIC_PAYMENT_CRYPTO_ROUTES.UPDATE(countryId, automaticPaymentRouteId),
        data: payload
    });
}

const deleteAutomaticPaymentCryptoRoute = async (countryId, automaticPaymentRouteId) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.AUTOMATIC_PAYMENT_CRYPTO_ROUTES.DELETE(countryId, automaticPaymentRouteId)
    });
}

const createAutomaticPaymentCryptoRoute = async (countryId, payload) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.AUTOMATIC_PAYMENT_CRYPTO_ROUTES.CREATE(countryId),
        data: payload
    });
}

// Automatic payments fiat rules
const retrieveBeneficairyMaxMonthlyLimitAmount = async (countryId) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRIES.GET_AUTOMATIC_PAYMENT_BENEFICIARY_MAX_MONTHLY_LIMIT_AMOUNT(countryId)
    });
}

const updateBeneficairyMaxMonthlyLimitAmount = async (countryId, payload) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.COUNTRIES.SET_AUTOMATIC_PAYMENT_BENEFICIARY_MAX_MONTHLY_LIMIT_AMOUNT(countryId),
        data: payload
    });
}
export default {
    fetchProvinces,
    fetchCountries,
    activateCashout,
    deactivateCashout,
    fetchCountriesAdmin,
    fetchFilterCountriesAdmin,
    fetchCities,
    getLimits,
    setLimits,
    getActiveCountriesCurrencies,
    disabledCountriesCurrencies,
    enableCurrency,
    manageDefaultCurrency,
    retrieveAutomaticPaymentRoutes,
    updateAutomaticPaymentRoute,
    createAutomaticPaymentRoute,
    deleteAutomaticPaymentRoute,
    retrieveAutomaticPaymentCryptoRoutes,
    updateAutomaticPaymentCryptoRoute,
    createAutomaticPaymentCryptoRoute,
    deleteAutomaticPaymentCryptoRoute,
    retrieveBeneficairyMaxMonthlyLimitAmount,
    updateBeneficairyMaxMonthlyLimitAmount,
    fetchCountryPayoutProviders,
    updateCountryPayoutProvider,
    fetchRegisterCountries
};
