import React from "react";
import { List, Datagrid, TextField, EditButton, BooleanField } from "react-admin";
import { Pagination } from "../../../Components";
import { useAccessRole } from "../../../hooks";

const CryptoPayoutProvidersList = (props) => {
  const { isAccess } = useAccessRole();

  return (
    <List
      {...props}
      title={"Proveedores de pagos crypto"}
      pagination={<Pagination />}
      exporter={false}
      bulkActionButtons={false}
      debounce={1000}
    >
      <Datagrid>
        <TextField source="attributes.id" label="Id" />
        <TextField source="attributes.name" label="Nombre" />
        <TextField source="attributes.code" label="Código" />
        <BooleanField source="attributes.is_active" label="Activo" />
        <BooleanField source="attributes.autopay_balance_validation" label="Validación de balance (Autopay)" />
        {
          isAccess('edit_crypto_payout_providers') &&
          <EditButton />
        }
      </Datagrid>
    </List>
  );
};

export default CryptoPayoutProvidersList;
