import React from "react";
import { Filter, TextInput } from "react-admin";

import ButtonClearFilters from "../../ButtonClearFilters";

const Filters = (props) => {
  return(
    <Filter {...props}>
      <ButtonClearFilters setFilters={props.setFilters} alwaysOn/>
      <TextInput
        label={"Buscar"}
        source={"search_key"}
        helperText={"Busca por nombre"}
        alwaysOn
      />
    </Filter>
)};

export default Filters;
