import { GET_LIST, UPDATE, GET_ONE } from 'react-admin';

const responseHandler = (response, type) => {
  switch (type) {
    case GET_LIST: {
      return {
        data: response.data.data,
        total: response.data.data.length
      }
    }
    case GET_ONE: {
      return {
        data: response.data.data
      }
    }
    case UPDATE: {
      return {
        data: response.data.data,
      }
    }
  }
};


export default responseHandler;