import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import { CountriesProvider } from "../Providers";
import useAlerts from "./useAlerts";
import useAccessRole from "./useAccessRole";
import useReset from "./useReset";

const useCountryPayoutProvider = (idCountry) => {
  const { successMessage, errorMessage } = useAlerts();
  const { isAccess } = useAccessRole();
  const { resetList } = useReset();

  const [providersList, setprovidersList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [itemUpdate, setItemUpdate] = useState({
    id: 0,
    autopay_balance_validation: false,
    is_active: false
  });

  const getProviders = async () => {
    try {
      const {
        data: { data },
      } = await CountriesProvider.fetchCountryPayoutProviders(idCountry);
      if (data.length) {
        setprovidersList(data.map((item) => item.attributes));
      }
    } catch (error) {}
  };

  const hanleOnChangeStatus = async (item) => {
    if (isAccess("edit_payout_providers")) {
      let data = { status: item.status };

      if (data.status === "active") {
        data.status = "inactive";
      } else {
        data.status = "active";
      }

      try {
        await CountriesProvider.updateCountryPayoutProvider(idCountry, item.id, data);
        resetList();
      } catch (error) {}
    } else {
      return errorMessage("No posee permisos para esta operación");
    }
  };

  const handleUpdateProvider = async () => {
    if (isAccess("edit_payout_providers")) {
      try {
        const formData = new FormData();
        formData.append("autopay_balance_validation", itemUpdate.autopay_balance_validation);
        formData.append("is_active", itemUpdate.is_active);

        const response = await CountriesProvider.updateCountryPayoutProvider(
          idCountry,
          itemUpdate.id,
          formData
        );

        handleCloseModal();

        if (response.status === 200 || response.status === 204) {
          resetList();
        }
      } catch (error) {
      }
    } else {
      return errorMessage("No posee permisos para esta operación");
    }
  };

  const handleOpenModal = (item) => {
    setItemUpdate({
      id: item.id,
      autopay_balance_validation: item.autopay_balance_validation,
      is_active: item.is_active,
    });

    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setItemUpdate({
      id: 0,
      autopay_balance_validation: false,
      is_active: false
    });
    setIsOpenModal(false);
  };

  const handleOnchange = (targetName, targetValue) => {
    setItemUpdate({
      ...itemUpdate,
      [targetName]: targetValue,
    });
  }

  useEffect(() => {
    if (idCountry && isAccess("show_payout_providers")) {
      getProviders();
    }
  }, []);

  return {
    handleCloseModal,
    handleOnchange,
    handleOpenModal,
    handleUpdateProvider,
    hanleOnChangeStatus,
    isOpenModal,
    itemUpdate,
    providersList
  };
};

export default useCountryPayoutProvider;
