import React from 'react';
import { Filter, TextInput } from 'react-admin';

import ButtonClearFilters from '../../../Components/ButtonClearFilters';

const AdminFilter = (props) => {
  return (
    <Filter {...props}>
      <ButtonClearFilters setFilters={props.setFilters} alwaysOn/>
      <TextInput
        label={'Buscar'}
        source={'search_key'}
        helperText={"Busca un usuario por id, nombre, apellido, email."}
        alwaysOn
      />
    </Filter>
  );
};

export default AdminFilter;
