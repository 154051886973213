import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import styles from './styles';
import { WALLET_TRANSACTIONS, WALLET_DESTINATION, WALLET_ORIGIN } from '../utils';

const SafeTransactionTab = (props) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    spacing={24}
    style={styles.container}>
    <Grid item>
      <Button
        onClick={() => {
          props.history.push(`/${WALLET_TRANSACTIONS}`);
        }}
        style={props.resource === WALLET_TRANSACTIONS
          ? { ...styles.selected, ...styles.item }
          : styles.item}>
        Retiro de fondos
      </Button>
    </Grid>
    <Grid item>
      <Button
        onClick={() => {
          props.history.push(`/${WALLET_DESTINATION}`);
        }}
        style={props.resource === WALLET_DESTINATION
          ? { ...styles.selected, ...styles.item }
          : styles.item}>
        Wallets destino
      </Button>
    </Grid>
    <Grid item>
      <Button
        onClick={() => {
          props.history.push(`/${WALLET_ORIGIN}`);
        }}
        style={props.resource === WALLET_ORIGIN
          ? { ...styles.selected, ...styles.item }
          : styles.item}>
        Wallets origen
      </Button>
    </Grid>
  </Grid>
)

SafeTransactionTab.propTypes = {
  resource: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
}

export default SafeTransactionTab;