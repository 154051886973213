import { 
  useState, 
  useCallback, 
  useEffect, 
  useRef, 
  useMemo 
} from "react";
import Swal from 'sweetalert2';
import useReset from "./useReset";
import useAccessRole from "./useAccessRole";
import useAlerts from "./useAlerts";
import { CustodyWalletsProvider } from "../Providers";
import { 
  WALLET_TRANSACTIONS, 
  WALLET_DESTINATION, 
  WALLET_ORIGIN, 
  DEFAULT_FORMDATA 
} from "../Resources/CryptoTranslate/components/utils";

const useCryptoTranslate = (props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [networkSettings, setNetworkSettings] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [walletId, setWalletId] = useState(0);
  const [walletsDestintation, setWalletsDestintation] = useState([]);
  const [isWalletsDestintationActive, seIstWalletsDestintationActive] = useState(false);
  const [walletsOrigin, setWalletsOrigin] = useState([]);
  const [balance, setBalance] = useState(0);
  const [currency, setCurrency] = useState("");
  const { resetList } = useReset();
  const { showLoading, hiddenLoading } = useAlerts();

  const [formData, setFormData] = useState(DEFAULT_FORMDATA);  

  const [options, setOptions] = useState([]);
  const isFetch = useRef(true);

  const { isAccess } = useAccessRole();

  const fetchOptions = async () => {
    setOptions([]);
    if (isAccess('show_create_configuration_custody_wallet')){
      try {
        const response = await CustodyWalletsProvider.getCustodyWalletsSettings();
        if (response.data) {
          setOptions(response.data)
        } else {
          console.error(response);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchWalletsOrigin = async () => {
    if (isAccess('show_custody_wallets')) {
      try {
        if (props.resource === WALLET_TRANSACTIONS) {
          const response = await CustodyWalletsProvider.getCustodyWalletsList(0, formData.currency);
          setWalletsOrigin(response.data.wallets.data);
          setCurrency(response.data.wallets.data[0].attributes.currency.iso_code)
        }
      } catch {

      }
    }
  };

  const fetchWalletsDestination = async () => {
    if (isAccess('show_custody_wallets')) {
      try {
        if (props.resource === WALLET_TRANSACTIONS) {
          const walletOrigin = walletsOrigin.find(
            (wallet) => wallet.id === formData.walletOriginId
          );          
          const response = await CustodyWalletsProvider.getCustodyWalletsList(1, formData.currency, walletOrigin.attributes.network.id);
          setWalletsDestintation(response.data.wallets.data);
        }
      } catch {

      }
    }
  };

  const fetchBalance = async () => {
    if (isAccess('get_provider_balance_from_custody')){
      try {
        const response = await CustodyWalletsProvider.getBalanceCustodyWallets(formData.walletOriginId);
        setBalance(response.data.balance);
      } catch {
        setBalance(0);
      }
    }
  };

  useEffect(() => {
    if(!!formData.currency) {
      setWalletsDestintation([]);
      setFormData({
        ...formData,
        walletOriginId: 0,
        walletDestinationId: 0,
      });
      seIstWalletsDestintationActive(false);
      fetchWalletsOrigin();
    }
  }, [formData.currency]);

  useEffect(() => {
    if (!!formData.walletOriginId) {
      seIstWalletsDestintationActive(true);
      fetchBalance();
      fetchWalletsDestination();
    }
  }, [formData.walletOriginId]);

  const submitWallet = async (body) => {
    if (!isUpdate) {
      await CustodyWalletsProvider.createCustodyWallet(body);
    }
    else {
      await CustodyWalletsProvider.updateCustodyWallet(walletId, body);
    }
    closeModal();

    resetList();

    Swal.fire({
      title: "¡Wallet guardada!",
      icon: "success",
      draggable: true,
      confirmButtonText: "Entendido"
    });
  }

  const createTransaction = async (body) => {
    showLoading();
    await CustodyWalletsProvider.createCustodyWalletTransaction(body);
    hiddenLoading();
    closeModal();

    resetList();

    Swal.fire({
      title: "¡Transacción guardada!",
      icon: "success",
      draggable: true,
      confirmButtonText: "Entendido"
    });
  }

  const resourceStrtegy = {
    [WALLET_TRANSACTIONS]: {
      isValid: () => {
        return (
          !!formData.currency &&
          !!formData.walletOriginId &&
          !!formData.walletDestinationId &&
          !!formData.amount &&
          !!formData.description &&
          formData.amount > 0 &&
          formData.amount <= balance
        );
      },
      onSubmit: async () => {
        const body = {
          amount: formData.amount.trim(),
          destination_wallet_id: formData.walletDestinationId,
          origin_wallet_id: formData.walletOriginId,
          currency_id: formData.currency,
          description: formData.description.trim(),
        }
        try {
          await createTransaction(body);
        }
        catch (error) {
          Swal.fire({
            title: error.data.message,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Volver a intentar",

          }).then(async (result) => {
            if (result.isConfirmed) {
              openModal();
            }
          });
        }
      }
    },
    [WALLET_DESTINATION]: {
      isValid: () => {
        return !!formData.walletName &&
        !!formData.currency &&
        !!formData.network &&
        !!formData.walletDirection
      },
      onSubmit: async () => {
        const body = {
          wallet_name: formData.walletName.trim(),
          currency_id: formData.currency,
          network_id: formData.network,
          address: formData.walletDirection.trim(),
          description: formData.description.trim(),
          wallet_type: "destination"
        }
        try {
          await submitWallet(body);
        }
        catch (error) {
          Swal.fire({
            title: error.data.message,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Volver a intentar",

          }).then(async (result) => {
            if (result.isConfirmed) {
              openModal();
            }
          });
        }
      }
    },
    [WALLET_ORIGIN]: {
      isValid: () => {
        return !!formData.walletName &&
          !!formData.currency &&
          !!formData.externalId &&
          !!formData.network &&
          !!formData.walletDirection
      },
      onSubmit: async () => {
        const body = {
          wallet_name: formData.walletName.trim(),
          currency_id: formData.currency,
          external_provider: options.external_providers[0].id,
          external_id: formData.externalId.trim(),
          network_id: formData.network,
          address: formData.walletDirection.trim(),
          contract: formData.contract.trim(),
          description: formData.description.trim(),
          wallet_type: "custody"
        }
        try {
          await submitWallet(body);
        }
        catch(error) {
          Swal.fire({
            title: error.data.message,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Volver a intentar",

          }).then(async (result) => {
            if (result.isConfirmed) {
              openModal();
            }
          });
        }
      }
    }
  }

  const strategy = resourceStrtegy[props.resource] || {};

  const isValid = useMemo(() => strategy.isValid?.() || false, [formData, strategy]);
  
  const onSubmit = useCallback(() => strategy.onSubmit?.() || false, [formData, strategy]);
 
  useEffect(() => {
    if (isFetch.current) {
      (async () => {
        try {
          await fetchOptions();
        } catch { }
      })();
    }

    return () => {
      isFetch.current = false;
    }
  }, []);

  const handleChange = (event) => {

    if (event.target.name === "currency") {      
      const currency = options.currencies.find(c => c.id === event.target.value);
      setNetworkSettings(currency.networks)     
    }

    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const openModal = () => {
    if ((props.resource === WALLET_TRANSACTIONS && isAccess('create_custody_transaction')) ||
      ((props.resource === WALLET_DESTINATION || props.resource === WALLET_ORIGIN) && isAccess('create_custody_wallet'))) {
      setIsOpenModal(true);
    }
  };

  const closeModal = () => {
    seIstWalletsDestintationActive(false);
    setWalletId(0);
    if (isUpdate) {
      setIsUpdate(false);
    }
    setFormData(DEFAULT_FORMDATA);
    setIsOpenModal(false);
  };

  const handleEdit = async (id) => {
    if (isAccess('edit_custody_wallet' && isAccess('show_custody_wallet'))) {
      const response = await CustodyWalletsProvider.getCustodyWalletById(id);
      const wallet = response.data.data.attributes;

      setWalletId(response.data.data.id);
      setNetworkSettings([wallet.network])
      
      setFormData({
        walletName: wallet.wallet_name || "",
        walletDirection: wallet.address || "",
        currency: wallet.currency.id || "",
        network: wallet.network.id || "",
        description: wallet.description || "",
        externalId: wallet.external_id || "",
        contract: wallet.contract || "",
      })
      setIsUpdate(true);
      openModal();
    }
  }

  const handleDelete = async (id) => {
    if (isAccess('delete_custody_wallet')) {
      Swal.fire({
        title: "¿Estás seguro que deseas eliminar la wallet?",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#E9320A",
        cancelButtonColor: "",
        confirmButtonText: "Eliminar",

      }).then(async (result) => {
        if (result.isConfirmed) {
          await CustodyWalletsProvider.deleteCustodyWallet(id);
          resetList();
        }
      });
    }
  }

  return {
    ...props,
    openModal,
    closeModal,
    isOpenModal,
    handleChange,
    formData,
    currenciesOptions: options.currencies,
    externalProvidersOptions: options.external_providers,
    isValid,
    onSubmit,
    networkSettings,
    handleEdit,
    isUpdate,
    handleDelete,
    walletsDestintation,
    walletsOrigin,
    balance,
    currency,
    isWalletsDestintationActive,
  };
};

export default useCryptoTranslate;