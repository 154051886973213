import React, { useState, useEffect } from 'react';
import { Filter, TextInput, SelectInput, DateInput, BooleanInput } from 'react-admin';

import { VERIFICATION, CATEGORIES, USER_CATEGORIES, USD_ACCOUNT_STATUSES } from './utils';

import { Grid, withStyles } from '@material-ui/core';
import Countries from '../../../../Providers/Countries';
import { EXTERNAL_PROVIDERS } from '../../../Transactions/Filters/TransactionFilter/utils';
import ButtonClearFilters from '../../../../Components/ButtonClearFilters';

const styles = {
    helperText: {
        color: ' rgba(255, 255, 255, 0.7)',
        margin: '8px 0',
        fontSize: '0.75rem',
        textAlign: 'left',
        lineHeight: '1em'
    },
    dateInput: {
        margin: '8px 0 0 0',
        '&:nth-child(1)': {
            paddingRight: '5px'
        },
        '&:nth-child(2)': {
            paddingLeft: '5px'
        },
        '& input[type="date"]::-webkit-calendar-picker-indicator': {
            backgroundColor: ' rgb(140, 140, 140)',
            borderRadius: '5px',
            display: 'block'
        }
    }
};

const UserFilter = (props) => {
    const { classes, setFilters } = props;

    const [birtCountries, setBirtCountries] = useState([]);
    const [residenceCountries, setResidenceCountries] = useState([]);

    const getBirtCountries = async () => {
        const response = await Countries.fetchCountriesAdmin(true);
        setBirtCountries(response.data.countries.data);
    }

    const getResidenceCountries = async () => {
        const response = await Countries.fetchFilterCountriesAdmin("q[is_register_eq]=true");
        setResidenceCountries(response.data.countries.data);
    }

    useEffect(() => {
        getBirtCountries();
    }, []);

    useEffect(() => {
        getResidenceCountries();
    }, []);


    return (
        <Filter {...props}>
            <ButtonClearFilters setFilters={setFilters} alwaysOn/>
            <TextInput
                label={'Id'}
                source={'id'}
                helperText={"Id del usuario"}
                alwaysOn
            />
            <TextInput
                label={'Buscar'}
                source={'search_key'}
                helperText={"Nombre, apellido, email, DNI o número de teléfono"}
                alwaysOn
            />
            {/* <TextInput
                label={'Email'}
                source={'email'}
                alwaysOn
            /> */}
            <SelectInput
                label={'Verificación'}
                source={'verification'}
                choices={VERIFICATION}
                helperText={"Filtra el usuario por estado de solicitud"}
            />

            <SelectInput
                label={'Tipo de transacción'}
                source={'category'}
                choices={CATEGORIES}
                helperText={"Filtra el usuario por tipo de transacción"}
            />

            <SelectInput
                label={'Proveedor de transacción'}
                source={'external_provider'}
                choices={EXTERNAL_PROVIDERS}
                helperText={"Filtra el usuario por proveedor de transacción"}
            />

            <Grid
                label="Fecha de transacciones"
                source={'transaction_dates'}
            >
                <Grid
                    container
                >
                    <DateInput
                        label={'Desde'}
                        source="transaction_dates.transaction_date_start"
                        className={classes.dateInput}
                    />
                    <DateInput
                        label={'Hasta'}
                        source="transaction_dates.transaction_date_end"
                        className={classes.dateInput}
                    />
                </Grid>
                <p className={classes.helperText}>Fecha de transacciones</p>
            </Grid>

            <Grid
                label="Fecha de registro"
                source={'register_dates'}
            >
                <Grid
                    container
                >
                    <DateInput
                        label={'Desde'}
                        source="register_dates.register_date_start"
                        className={classes.dateInput}
                    />
                    <DateInput
                        label={'Hasta'}
                        source="register_dates.register_date_end"
                        className={classes.dateInput}
                    />
                </Grid>
                <p className={classes.helperText}>Fecha de registro</p>
            </Grid>

            <Grid
                label="Fecha de verificación"
                source={'verification_dates'}
            >
                <Grid
                    container
                >
                    <DateInput
                        label={'Desde'}
                        source="verification_dates.verification_date_start"
                        className={classes.dateInput}
                    />
                    <DateInput
                        label={'Hasta'}
                        source="verification_dates.verification_date_end"
                        className={classes.dateInput}
                    />
                </Grid>
                <p className={classes.helperText}>Fecha de verificación</p>
            </Grid>
            <SelectInput
                label={'País de nacimiento'}
                source={'residence_country'}
                choices={birtCountries}
                optionValue="id"
                optionText="attributes.name"
                helperText={"Paises"}
            />
            <SelectInput
                label={'País de residencia'}
                source={'country'}
                choices={residenceCountries}
                optionValue="id"
                optionText="attributes.name"
                helperText={"Paises"}
            />
            <SelectInput
                label={'Categoría'}
                source={'user_category'}
                choices={USER_CATEGORIES}
                helperText={"Tipo de usuario"}
            />
            <BooleanInput
                label={'Cuenta USD'}
                source={'is_usd_account'}
            />
            <SelectInput
                label={'Estado de cuenta en EE.UU'}
                source={'usd_account_status'}
                choices={USD_ACCOUNT_STATUSES}
                helperText={"Filtra el usuario por estado de cuenta en EE.UU."}
            />
        </Filter>
    );
};

export default withStyles(styles)(UserFilter);
