import React from "react";

import { Grid, Button, Paper, Table, TableHead, TableRow, TableCell, Switch, FormControlLabel, TableBody, Checkbox } from "@material-ui/core";
import { SelectInput } from 'react-admin';
import { PAYMENT_PROCESSORS } from "../../../../../../../Utils";
import useCountryPayoutProvider from "../../../../../../../hooks/useCountryPayoutProvider";
import EditPaymentProvider from "../EditPaymentProvider";

const Payment = (props) => {
  const {
    handleCloseModal,
    handleOnchange,
    handleOpenModal,
    handleUpdateProvider,
    hanleOnChangeStatus,
    isOpenModal,
    itemUpdate,
    providersList,
  } = useCountryPayoutProvider(props.id);

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="center">
        <SelectInput
          native
          label="Seleccione el proveedor de pago"
          source="attributes.payment_processor"
          choices={PAYMENT_PROCESSORS}
          style={{ width: 350 }}
        />
      </Grid>

      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{'ID'}</TableCell>
              <TableCell>{'ACTIVO'}</TableCell>
              <TableCell>{'PROVEEDOR'}</TableCell>
              <TableCell>{'COSTO FIJO'}</TableCell>
              <TableCell>{'VALIDACIÓN DE BALANCE (AUTOPAY)'}</TableCell>
              <TableCell>{'EDITAR'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {providersList.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell> {item.id} </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={item.is_active}
                      inputProps={{ "aria-label": "Checkbox A" }}
                    />
                  </TableCell>
                  <TableCell> {item.payouts_provider?.name} </TableCell>
                  <TableCell> {item.fixed_cost} </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={item.autopay_balance_validation}
                      inputProps={{ "aria-label": "Checkbox A" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleOpenModal(item)}
                      data-testid='edit-button'
                    >
                      Editar
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <EditPaymentProvider
        handleCloseModal={handleCloseModal}
        handleOnchange={handleOnchange}
        handleUpdateProvider={handleUpdateProvider}
        isOpenModal={isOpenModal}
        itemUpdate={itemUpdate}
      />
    </>
  )
};

export default Payment;
