import React from 'react';
import PropTypes from 'prop-types';
import {Button, Grid} from "@material-ui/core";

const CryptoTranslateButtons = (props) => (
  <Grid container direction="row" spacing={24}>
    <Grid item>
      <Button
        style={{
          backgroundColor: "#005FEE",
          color: "fff",
        }}
        onClick={() => props.handleEdit(props.record.id)}
      >
        Editar
      </Button>
    </Grid>
    <Grid item>
      <Button
        style={{
          backgroundColor: "#E9320A",
          color: "fff",
        }}
        onClick={() => props.handleDelete(props.record.id)}
      >
        Eliminar
      </Button>
    </Grid>
  </Grid>
)

CryptoTranslateButtons.propTypes = {
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  record: PropTypes.object,
};

export default CryptoTranslateButtons;
