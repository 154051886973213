import React, { useState } from "react";
import PropTypes from 'prop-types';
import { TabPanel } from "../components";
import {
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';

import useAccessRole from '../../../hooks/useAccessRole';
import { USDC, BTC, USDT } from "../Cryptos";

const CryptoWithdrawalList = (props) => {
  const { location: { search } } = props;
  const [labelTabName, setLabelTabName] = useState({isBitcoin: true})

  const { isAccess } = useAccessRole();

  let tab = 0;
  if (search && search.includes('tab')) {
    const onlyTab = search.match(/tab=\d+/);
    tab = onlyTab[0].split("=")[1];
  }

  const handleChange = (event, newValue) => {
    switch (true) {
      case newValue === 1:
        setLabelTabName({isCircle: true});
        break;
      case newValue === 2:
        setLabelTabName({isBinance: true});
        break;
      default:
        setLabelTabName({isBitcoin: true});
        break;
    }

    setValue(newValue);
  };

  const [value, setValue] = useState(parseInt(tab));

  return (
    <>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
        >
          <Tab label="BTC" disabled={!isAccess('show_btc_withdrawal')} />
          <Tab label="USDC" disabled={!isAccess('show_crypto_withdrawal')} />
          <Tab label="USDT" disabled={!isAccess('show_crypto_withdrawal')} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <BTC {...props} labelTabName={labelTabName}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <USDC {...props} labelTabName={labelTabName}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <USDT {...props} labelTabName={labelTabName}/>
      </TabPanel>
    </>
  );
};

CryptoWithdrawalList.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  })
}; 

export default CryptoWithdrawalList;
