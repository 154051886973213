import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TablePagination,
} from "@material-ui/core";

import { COLUNMS_LIST_LINKS } from "../../../utils";

import styles from "./styles";
import useUserBusinessLink from "../../../../../../../hooks/useUserBusinessLink";

const ListLinks = (props) => {
  const [showTable, setShowTable] = useState(props.showTable);

  const {
    hadleCopyLink,
    handleChangePageLinks,
    handleChangeRowsPerPageLinks,
    listLinksUser,
    pageList,
    getLinksPayment,
  } = useUserBusinessLink(props);

  useEffect(() => {
    showTable &&
      getLinksPayment({ page: pageList.page, count: pageList.count });
  }, [showTable]);

  const { title } = props;

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <Typography variant='body1' gutterBottom style={styles.titleProvider}>
          {title}
        </Typography>
        {!showTable && (
          <Button
            onClick={() => setShowTable(true)}
            variant='contained'
            color='primary'
            data-testid='download-button'
            style={{ marginLeft: 10 }}
          >
            Cargar
          </Button>
        )}
      </header>

      {showTable &&
        (listLinksUser.length === 0 ? (
          <div style={styles.noLinks}>No hay enlaces generados.</div>
        ) : (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  {COLUNMS_LIST_LINKS.map((colum, index) => {
                    return <TableCell key={index}>{colum}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {listLinksUser.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        {" "}
                        {moment(item.createDate).format("DD/MM/YYYY HH:mm")}{" "}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {moment(item.expirationDate).format("DD/MM/YYYY HH:mm")}{" "}
                      </TableCell>
                      <TableCell> {` ${item.amount.toLocaleString() } ${item.currency}`} </TableCell>
                      <TableCell> {item.status} </TableCell>
                      <TableCell> {item.link} </TableCell>
                      <TableCell> {item.concept} </TableCell>

                      <TableCell>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() => hadleCopyLink(item.link)}
                          data-testid='copy-button'
                        >
                          Copiar enlace
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              component='div'
              count={pageList.total}
              rowsPerPage={pageList.count}
              page={pageList.page}
              backIconButtonProps={{
                "aria-label": "Atras",
              }}
              nextIconButtonProps={{
                "aria-label": "Siguiente",
              }}
              onChangePage={handleChangePageLinks}
              onChangeRowsPerPage={handleChangeRowsPerPageLinks}
            />
          </Paper>
        ))}
    </div>
  );
};

ListLinks.propTypes = {
  listLinksUser: PropTypes.array,
  hadleCopyLink: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  pageList: PropTypes.shape({
    total: PropTypes.number,
    count: PropTypes.number,
    page: PropTypes.number,
  }),
  title: PropTypes.string,
  showTable: PropTypes.bool,
  loadConfig: PropTypes.bool,
};

ListLinks.defaultProps = {
  listLinksUser: [],
  hadleCopyLink: () => {},
  handleChangePage: () => {},
  handleChangeRowsPerPage: () => {},
  pageList: {
    total: 0,
    count: 0,
    page: 0,
  },
  title: 'Enlaces generados',
  showTable: true,
  loadConfig: true
};

export default ListLinks;
