import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const getCustodyWalletsSettings = async () => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CUSTODY_WALLETS.GET_SETTINGS(),
  });
};

const getBalanceCustodyWallets = async (currency) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CUSTODY_WALLETS.GET_BALANCE(currency),
  });
};

const getCustodyWalletsList = async (type, currency, network) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CUSTODY_WALLETS.GET_LIST(type, currency, network),
  });
};

const getCustodyWalletById = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CUSTODY_WALLETS.GET_ONE(id),
  });
};

const createCustodyWallet = async (data) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CUSTODY_WALLETS.CREATE(),
    data: data,
  });
};

const createCustodyWalletTransaction = async (data) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CUSTODY_WALLETS.CREATE_TRANSACTION(),
    data: data,
  });
};

const updateCustodyWallet = async (id, data) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CUSTODY_WALLETS.UPDATE(id),
    data: data,
  });
};

const deleteCustodyWallet = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.CUSTODY_WALLETS.DELETE(id)
  });
};

export default {
  getCustodyWalletsSettings,
  getCustodyWalletsList,
  getCustodyWalletById,
  getBalanceCustodyWallets,
  createCustodyWallet,
  createCustodyWalletTransaction,
  updateCustodyWallet,
  deleteCustodyWallet,
};