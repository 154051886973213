import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { Button } from '@material-ui/core';
import { CryptoTranslateTab, CryptoTranslateButtons, WalletOriginModal, WalletTransactiosModal, WalletDestinationModal } from '..'
import useCryptoTranslate from '../../../../hooks/useCryptoTranslate';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { WALLET_DESTINATION, WALLET_ORIGIN, WALLET_TRANSACTIONS } from '../utils';
import { Pagination } from '../../../../Components';
import { useAccessRole } from '../../../../hooks';

const WalletList = withStyles(styles)(
  ({ classes, ...props }) => {
    const { isAccess } = useAccessRole();

    return (
      <>
        <CryptoTranslateTab history={props.history} resource={props.resource} />
        <div style={{ position: "relative" }}>
          <Button
            style={{
              position: "absolute",
              top: 15,
              right: props.resource === WALLET_TRANSACTIONS ? 120 : 50,
              zIndex: 100,
              backgroundColor: "#005FEE",
            }}
            onClick={props.openModal}
          >
            {props.resource === WALLET_TRANSACTIONS ? "RETIRAR FONDOS" : "AGREGAR WALLET"}
          </Button>
          {
            props.resource === WALLET_TRANSACTIONS && (
              <>
                <List
                  {...props}
                  title="Traslados cripto"
                  bulkActionButtons={false}
                  exporter={isAccess('get_wallet_transactions') ? () => { } : false}
                  pagination={<Pagination />}>
                  <Datagrid>
                    <TextField source={"id"} label={"Id de transacción"} />
                    <TextField source={"wallet_origin"} label={"Wallet de origen"} />
                    <TextField source={"wallet_destination"} label={"Wallet de destino"} />
                    <TextField source={"amount"} label={"Monto"} />
                    <TextField source={"network"} label={"Red"} />
                    <TextField source={"created_at"} label={"Fecha"} />
                    <TextField source={"admin_email"} label={"Realizada por"} />
                    <TextField source={"description"} label={"Descripción"} />
                  </Datagrid>
                </List>
                <WalletTransactiosModal
                  closeModal={props.closeModal}
                  isOpenModal={props.isOpenModal}
                  formData={props.formData}
                  handleChange={props.handleChange}
                  currenciesOptions={props.currenciesOptions}
                  isValid={props.isValid}
                  onSubmit={props.onSubmit}
                  networkSettings={props.networkSettings}
                  walletsDestintation={props.walletsDestintation}
                  walletsOrigin={props.walletsOrigin}
                  balance={props.balance}
                  currency={props.currency}
                  isWalletsDestintationActive={props.isWalletsDestintationActive}
                />
              </>
            )
          }
          {
            props.resource === WALLET_DESTINATION && (
              <>
                <List
                  {...props}
                  title="Traslados cripto"
                  bulkActionButtons={false}
                  exporter={false}
                  pagination={<Pagination />}>
                  <Datagrid classes={{ headerRow: classes.lastHeader }}>
                    <TextField source={"wallet_name"} label={"Nombre"} />
                    <TextField source={"currency"} label={"Moneda"} />
                    <TextField source={"network_name"} label={"Red"} />
                    <TextField source={"address"} label={"Wallet"} />
                    <TextField source={"description"} label={"Descripcion"} />
                    <CryptoTranslateButtons
                      source={""}
                      label={"Aciones"}
                      handleEdit={props.handleEdit}
                      handleDelete={props.handleDelete} />
                  </Datagrid>
                </List>
                <WalletDestinationModal
                  closeModal={props.closeModal}
                  isOpenModal={props.isOpenModal}
                  formData={props.formData}
                  handleChange={props.handleChange}
                  currenciesOptions={props.currenciesOptions}
                  externalProvidersOptions={props.externalProvidersOptions}
                  isValid={props.isValid}
                  onSubmit={props.onSubmit}
                  networkSettings={props.networkSettings}
                  isUpdate={props.isUpdate}
                />
              </>
            )
          }
          {
            props.resource === WALLET_ORIGIN && (
              <>
                <List
                  {...props}
                  title="Traslados cripto"
                  bulkActionButtons={false}
                  exporter={false}
                  pagination={<Pagination />}>
                  <Datagrid classes={{ headerRow: classes.lastHeader }}>
                    <TextField source={"wallet_name"} label={"Nombre"} />
                    <TextField source={"currency"} label={"Criptomoneda"} />
                    <TextField source={"external_id"} label={"Wallet ID DFNS"} />
                    <TextField source={"network_name"} label={"Red"} />
                    <TextField source={"address"} label={"Wallet"} />
                    <TextField source={"description"} label={"Descripcion"} />
                    <CryptoTranslateButtons
                      source={""}
                      label={"Aciones"}
                      handleEdit={props.handleEdit}
                      handleDelete={props.handleDelete} />
                  </Datagrid>
                </List>
                <WalletOriginModal
                  closeModal={props.closeModal}
                  isOpenModal={props.isOpenModal}
                  formData={props.formData}
                  handleChange={props.handleChange}
                  currenciesOptions={props.currenciesOptions}
                  externalProvidersOptions={props.externalProvidersOptions}
                  isValid={props.isValid}
                  onSubmit={props.onSubmit}
                  networkSettings={props.networkSettings}
                  isUpdate={props.isUpdate}
                />
              </>
            )
          }
        </div>
      </>
    );
  }
);

export default (props) => {
  const hook = useCryptoTranslate(props);
  return <WalletList {...hook} />;
};