import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SaveButton,
  Toolbar,
} from "react-admin";

const CryptoPayoutsProviderEdit = (props) => {
  const CryptoPayoutsProviderEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Edit {...props} title={`Editar proveedor ${props.id}`}>
      <SimpleForm toolbar={<CryptoPayoutsProviderEditToolbar />}>
        <TextInput 
          disabled
          label="Id"
          source="id"
        />
        <TextInput
          disabled
          source="attributes.name"
          label="Nombre"
        />
        <TextInput
          disabled
          source="attributes.code"
          label="Código"
        />
        <BooleanInput
          source={'attributes.is_active'}
          label={'Activo'}
        />
        <BooleanInput
          source={'attributes.autopay_balance_validation'}
          label={'Validación de balance (Autopay)'}
        />
      </SimpleForm>
    </Edit>
  );
};

export default CryptoPayoutsProviderEdit;