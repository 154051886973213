const styles = {
  container: {
    backgroundColor: "#424242",
    width: "100%",
    paddingTop: 12,
    paddingBottom: 12,
    marginLeft: 0,
    borderRadius: 4,
  },
  selected: {
    borderBottomColor: "#2C80FF",
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
  },
  item: {
    fontSize: 12,
    borderRadius: 0,
  },
};

export default styles;